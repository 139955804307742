<template>
  <b-container fluid>
    <h4>Caixa</h4>
    <b-row>
      <b-col>
        <b-form @submit="submitForm">
          <form-input
            v-model="form.descricao"
            label="Descrição:"
            placeholder="Digite a descrição da caixa"
            icon="card-heading"
            required
          />

          <b-row>
            <b-col>
              <form-input
                v-model="form.altura"
                label="Altura (CM):"
                type="number"
                placeholder="Informe a altura em centímetros"
                icon="rulers"
                required
              />
            </b-col>
            <b-col>
              <form-input
                v-model="form.largura"
                label="Largura (CM):"
                type="number"
                placeholder="Informe a largura em centímetros"
                icon="rulers"
                required
              />
            </b-col>
            <b-col>
              <form-input
                v-model="form.comprimento"
                label="Comprimento (CM):"
                type="number"
                placeholder="Informe a comprimento em centímetros"
                icon="rulers"
                required
              />
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary">SALVAR</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import FormInput from "../../components/form/FormInput.vue";

export default {
  components: { FormInput },
  name: "detailsBox",
  data() {
    return {
      form: { ...this.$store.state.box.box },
    };
  },
  methods: {
    ...mapActions("box", ["createBox", "updateBox", "getOneBox"]),
    ...mapMutations(["setToasted"]),

    resetForm() {
      this.form = {
        id: "",
        descricao: "",
        altura: "",
        largura: "",
        comprimento: "",
      };
    },
    async submitForm(event) {
      event.preventDefault();
      const { form } = this;

      let formattedObj = {};
      Object.keys(form).forEach((objKey) => {
        if (objKey !== "id" && objKey !== "descricao") {
          formattedObj[objKey] = Number(form[objKey]);
        } else {
          formattedObj[objKey] = form[objKey];
        }
      });

      if (form.id) {
        this.updateBox(formattedObj);
      } else {
        this.createBox(formattedObj);
      }
    },
  },
  async created() {
    const { id } = this.$route.params;

    if (id && !this.form.id) {
      await this.getOneBox(id);
      this.form = { ...this.$store.state.box.box };
    }
  },
};
</script>

<style></style>
